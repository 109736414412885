import {type FC, memo, useMemo} from "react";
import {buttonBaseStyle, type ButtonProps, parseButtonAttrs} from "./base-button";
import styles from './filled-button.module.scss';

const FilledButton:FC<ButtonProps> = memo(function ({className = "", intent = "primary", children, onClick, attrs, ...props}) {
  const handleClick = useMemo(() => {
    if (props.disabled === true)
      return;
    
    return onClick;
  }, [props.disabled, onClick]);

  return <button 
    className={`${buttonBaseStyle({className, intent, ...props})} ${styles.filledButton}`} 
    onClick={handleClick} 
    {...parseButtonAttrs(attrs)}
    disabled={props.disabled}
  >
    {children}
  </button>
});

FilledButton.displayName = 'FilledButton';
export { FilledButton };