export function parseStringQueryParam( param : string | string [] | undefined ) : string | undefined {
  return Array.isArray(param)
  ? param[0]
  : param;
}

export function parseNumberQueryParam( param : string | string [] | undefined ) : number | undefined {
  const data = parseStringQueryParam(param);
  if (!data) return undefined;
  return parseFloat(data);
}

export function parseBoolean( value : string | string [] | undefined | boolean | number | null) : boolean | undefined {
  if (typeof value === 'undefined' || value === null)
    return;

  if (typeof value !== 'string')
    value = value.toString();

  return [true, 'true', '1', 1].includes(value.toLowerCase());
}

export function isNextNotFoundError(e: unknown) {
  return (e && typeof e === "object" && "message" in e && e.message === "NEXT_REDIRECT");
}