import { CheckCircle, Info, WarningCircle, XCircle } from '@phosphor-icons/react/dist/ssr';
import { type FC, memo, type ReactNode, useMemo, useState } from 'react';
import { type DismissibleNoticeData, type NoticeTypes } from './types';
import { cva, type VariantProps } from 'class-variance-authority';
import { Transition } from '@headlessui/react';

const wrapperStyles = cva('select-none rounded-lg p-4 text-sm', {
  variants: {
    type: {
      ERROR: 'bg-red-50 text-red-700 border-red-700',
      INFO: 'bg-sky-50 text-sky-700 border-sky-700',
      SUCCESS: 'bg-emerald-50 text-emerald-700 border-emerald-700',
      WARNING: 'bg-yellow-50 text-yellow-700 border-yellow-700',
    } as Record<NoticeTypes, string>,
    bordered: {
      true: 'border-2',
    },
    dismissible: {
      true: 'cursor-pointer transition-colors',
    },
  },
  compoundVariants: [
    {
      type: 'ERROR',
      dismissible: true,
      class: 'hover:bg-red-100 active:bg-red-50',
    },
    {
      type: 'INFO',
      dismissible: true,
      class: 'hover:bg-sky-100 active:bg-sky-50',
    },
    {
      type: 'SUCCESS',
      dismissible: true,
      class: 'hover:bg-emerald-100 active:bg-emerald-50',
    },
    {
      type: 'WARNING',
      dismissible: true,
      class: 'hover:bg-yellow-100 active:bg-yellow-50',
    },
  ],
  defaultVariants: {
    bordered: false
  }
});

const iconSize = 32;

type Props = DismissibleNoticeData &
  VariantProps<typeof wrapperStyles> & {
    as?: keyof JSX.IntrinsicElements;
    className?: string;
    dismissible?: boolean;
    colorizeText?: boolean;
    icon?: ReactNode,
  };

const Notice: FC<Props> = memo(
  ({
    as: Wrapper = 'div',
    message,
    onDismiss,
    className = '',
    type,
    dismissible = true,
    colorizeText = false,
    icon,
    ...props
  }) => {
    const [show, setShow] = useState(true);

    function handleDismiss() {
      if (!dismissible) return;

      setShow(false);
      setTimeout(() => {
        if (onDismiss) onDismiss();
      }, 150);
    }

    const Icon = useMemo(() => {
      switch (type) {
        case 'WARNING': 
          return (
            <WarningCircle
              weight="fill"
              size={iconSize}
            />
          );

        case 'ERROR':
          return (
            <XCircle
              weight="fill"
              size={iconSize}
            />
          );

        case 'INFO':
          return (
            <Info
              weight="fill"
              size={iconSize}
            />
          );

        case 'SUCCESS' :
          return (
            <CheckCircle
              weight="fill"
              size={32}
            />
          );
      }
    }, [type]);

    return (
      <Transition
        as={Wrapper}
        appear={true}
        show={show}
        className="transition-all !duration-150"
        enterFrom="opacity-0 scale-75"
        enterTo="opacity-100 scale-100"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-75"
      >
        <div
          className={wrapperStyles({ type, dismissible, className, ...props })}
          onClick={handleDismiss}
        >
          <div className="flex flex-row items-start">
            <div className="-ml-2 mr-2">
              {icon ? icon : Icon}
            </div>
            <div className={`${!colorizeText && 'text-grey-700'} flex flex-col justify-center`} style={{minHeight: iconSize}}>
              {message}
            </div>
          </div>
        </div>
      </Transition>
    );
  }
);

Notice.displayName = 'Notice';
export { Notice };