import {type ButtonHTMLAttributes, type FC, memo, type MouseEventHandler, type ReactNode, useMemo} from "react";
import {cva, type VariantProps} from "class-variance-authority";

export const buttonBaseStyle = cva(
  'rounded-lg font-semibold',
  {
    variants: {
      intent: {
        primary: 'bg-brand text-white shadow-brand-black disabled:cursor-not-allowed disabled:bg-slate-400 disabled:opacity-75',
        secondary: 'bg-grey-700 text-white shadow-brand',
        ghost: 'bg-transparent transition-colors hover:bg-black/5 active:bg-black/10 disabled:bg-transparent'
      },
      fullWidth: {
        true: 'w-full',
      },
      block: {
        true: 'block',
      },
      size: {
        xs: 'p-2',
        sm: 'min-h-[40px] px-3 py-2',
        md: 'p-4',
        lg: 'p-6'
      }
    },
    defaultVariants: {
      size: 'md'
    }
  }
);

export interface ButtonProps extends VariantProps<typeof buttonBaseStyle> {
  children: ReactNode,
  className?: string,
  onClick?: MouseEventHandler<HTMLOrSVGElement>,
  attrs?: ButtonHTMLAttributes<HTMLButtonElement>,
  disabled?: boolean,
}

export function parseButtonAttrs(attrs?: ButtonHTMLAttributes<HTMLButtonElement>) : ButtonHTMLAttributes<HTMLButtonElement>{
  return {
    type: 'button',
    ...attrs,
  }
}

const BaseButton:FC<ButtonProps> = memo(function ({className = "" ,children, onClick, attrs, ...props}, ) {
  const handleClick = useMemo(() => {
    if (props.disabled === true)
      return;
    
    return onClick;
  }, [props.disabled, onClick]);

  return <button 
    className={buttonBaseStyle({...props, className})} 
    onClick={handleClick} 
    {...parseButtonAttrs(attrs)}
    disabled={props.disabled}
  >
    {children}
  </button>
});

BaseButton.displayName = 'BaseButton';
export { BaseButton };