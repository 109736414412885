"use client";

import type {
  CenterCardData,
  StoreLocatorSearchResultDTO,
} from '@repo/api-client';
import { createContext, type PropsWithChildren, useContext, useRef } from 'react';
import { createStore, useStore } from 'zustand';
import { subscribeWithSelector } from 'zustand/middleware';

type StoreLocatorDisplayMode = 'MIXT' | 'MAP' | 'LIST';
export type StoreLocatorLocation = Omit<
  StoreLocatorSearchResultDTO['location'],
  'centers'
>;
export type StoreLocatorCenter = CenterCardData;

export const DEFAULT_DISTANCE = 50;
export const MIN_DISTANCE = 10;
export const MAX_DISTANCE = 100;

export type StoreLocatorQuery = {
  distance?: number;
  search?: string;
  gpsLat?: number;
  gpsLng?: number;
};

export type StoreLocatorUrlParams = {
  focus?: string,
  scrollToMap?: boolean,
}

export interface StoreLocatorData {
  query: StoreLocatorQuery;
  displayMode: StoreLocatorDisplayMode;
  isLoading: boolean;
  location?: StoreLocatorLocation;
  centers: StoreLocatorCenter[];
}

interface StoreLocatorActions {
  setDisplayMode: (mode: StoreLocatorDisplayMode) => void;
  setIsLoading: (isLoading: boolean) => void;
  setQuery: (query: StoreLocatorQuery) => void;
  setCenters: (centers: StoreLocatorCenter[]) => void;
  setLocation: (location: StoreLocatorLocation) => void;
}

type StoreLocatorState = StoreLocatorData & StoreLocatorActions;

const DEFAULT_PROPS: Readonly<StoreLocatorData> = {
  query: {},
  displayMode: 'LIST',
  isLoading: false,
  centers: [],
 }

const createStoreLocatorStore = (
  initialProps: Partial<StoreLocatorData>
) => {
  return createStore<StoreLocatorState>()(
    subscribeWithSelector((set) => ({
      ...DEFAULT_PROPS,
      ...initialProps,
      setDisplayMode: (displayMode: StoreLocatorDisplayMode) => set({displayMode}),
      setIsLoading: (isLoading: boolean) => set({ isLoading }),
      setQuery: (query: StoreLocatorQuery) => set({ query }),
      setCenters: (centers: StoreLocatorCenter[]) => set({ centers }),
      setLocation: (location: StoreLocatorLocation) => set({ location }),
    }))
  );
};

type StoreLocatorStore = ReturnType<typeof createStoreLocatorStore>;
const StoreLocatorContext = createContext<StoreLocatorStore | null>(null);
export function StoreLocatorProvider({
  children,
  ...props
}: PropsWithChildren<Partial<StoreLocatorData>>) {
  const storeRef = useRef<StoreLocatorStore>();
  if (!storeRef.current) {
    storeRef.current = createStoreLocatorStore(props);
  }

  return (
    <StoreLocatorContext.Provider value={storeRef.current!}>
      {children}
    </StoreLocatorContext.Provider>
  );
}

export function useStoreLocator<T>(
  selector: (state: StoreLocatorState) => T
): T {
  const store = useContext(StoreLocatorContext);
  if (!store) throw new Error('Missing <StoreLocatorProvider> in the tree');
  return useStore(store, selector);
}

export function useStaticStoreLocator() {
  const store = useContext(StoreLocatorContext);
  if (!store) throw new Error('Missing <StoreLocatorProvider> in the tree');
  return store;
}
